* {
  color: #fff;
}
.home-bg {
  background: linear-gradient(
    120deg,
    #e6007a -5%,
    #112769 5%,
    #703ac2 10%,
    #0070eb 34.18%,
    #0297fb 58.08%,
    #0ae2ff 74.93%
  );
}
