.container {
  background: #060b23;
  box-shadow: inset 0 0 4px #00000040;
  max-width: 700px;
  width: 100%;
}

.container-title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  padding: 16px 25px;
}

.border-line {
  border-top: 1px solid #1f2f5f;
}

.asset-item {
  padding: 16px 25px;
}

.asset-title {
  font-size: 16px;
  font-weight: 700;
  color: #f7f7f8;
}
.separator {
  border-bottom: 1px solid;
  border-image: linear-gradient(
    90deg,
    #e6007a -4%,
    #e6007a -3.88%,
    #703ac2 17.01%,
    #0070eb 42.25%,
    #0297fb 73.9%,
    #0ae2ff 97.42%
  );
  border-image-slice: 1;
}
.tooltip-text {
  /* 隐藏提示语 */
  display: none;
}

img:hover + .tooltip-text {
  /* 鼠标悬停时显示提示语 */
  display: block;
}
.scrollbar-hide::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar-hide::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
}
/* TODO: 自定义样式 */
.custom-tip > .ant-message-notice-content {
  background: #112769 !important;
  margin-top: 10vh;
}
/* TOOD: 最好使用style-component */
.tree-card-bg {
  background: #f7f7f808;
  box-shadow: 0 0 24px 5px #00000026;
}
